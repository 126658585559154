.ParticalBG {
    z-index: -5;
    position: relative;
}

.home {
    z-index: -1;
    position: relative;
}

h2 {
    color: white;
    z-index: 2;
    position: relative;
}

.HomeTitle {
    font-family: 'Roboto', sans-serif;
}


#IDOBG {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.homeMID {
    padding: 90px;
    width: 100vw;
    height: 50vh;
    color: white;
    background-color: black;
    opacity: 0.9;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
}


#JinKuBG {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
}

@media screen and (max-width: 900px) {
    .homeMID {
        padding: 80px;
        width: 100vw;
        height: 50vh;
        color: white;
        background-color: black;
        opacity: 0.9;
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
    }
}

#HomeTitle {
    margin-top: -5vh;
}

.HomeButton {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
}

.HomeBut {
    margin-top: 30px;
}
